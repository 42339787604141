export const CUSTOM_AVATARS_AND_NAMES_FOR_SUPPORT = {
	"9lBqAiSQOhg25NO5KW6lfQqIch42": {
		sex: "F",
		firstName: "Marie",
		lastName: "F.",
		email: "maryna.fedyk@gmail.com"
	},
	"kXrXwzUttCgtyC9gqqWLWhrwcvO2": {
		sex: "F",
		firstName: "Kate",
		lastName: "H.",
		email: "evkram87@gmail.com"
	},
	"C0Nqr01LelXYWv0YH5bNSm8m7nh1": {
		sex: "F",
		firstName: "Stacy",
		lastName: "C.",
		email: "anchizho@gmail.com"
	},
	"B0dKyphnWZgBgwoZa5EiNXpi0vb2": {
		sex: "F",
		firstName: "Joanna",
		lastName: "B.",
		email: "yanabril01@gmail.com"
	},
	"dG01QdZj3qZoH3rX1TqGsfuXtvT2": {
		sex: "F",
		firstName: "Sasha",
		lastName: "D.",
		email: "anjmirafuentes@gmail.com"
	},
	"lGb4O2Lr4bQWgjjFIpHhPtAn40m2": {
		sex: "M",
		firstName: "John",
		lastName: "S.",
		email: "tanduyan.johncarlo@dnsc.edu.ph"
	},
	"K79wyqqPlegcmP4FuPy6gfDDdNi2": {
		sex: "M",
		firstName: "Rey",
		lastName: "B.",
		email: "plukreybolo@gmail.com"
	},
	"ntXSrgXVtiO6D3iJMv5sz3IwMyU2": {
		sex: "M",
		firstName: "Elliott",
		lastName: "Y.",
		email: "i.yurchyna@gmail.com"
	},
	"fGOQOpeAuFdmwFkoEce1lvvk11M2": {
		sex: "M",
		firstName: "Max",
		lastName: "P.",
		email: "max.palka032@gmail.com"
	},
	"z1z19i3OQeSZicdjKxo71dz0Dds2": {
		sex: "F",
		firstName: "Nadine",
		lastName: "M.",
		email: "nadyadem.88@gmail.com"
	},
	"mK9aYWOiBATV0t9UvaCxj0Cqe4U2": {
		sex: "F",
		firstName: "Erin",
		lastName: "C.",
		email: "amecasidsid@gmail.com"
	},
	"ko6fzaOItkannnOi1j3VdoSm62n1": {
		sex: "F",
		firstName: "Jane",
		lastName: "E.",
		email: "jhane.escamos11@gmail.com"
	},
	"kfSGp36yH1XQCFNenIMoYuxrXSt1": {
		sex: "F",
		firstName: "Hannah",
		lastName: "B.",
		email: "annapershutkina@gmail.com"
	}
}


export const TOGGLE_UI_FEATURE = [
	"kozachok.dima83@outlook.com",
	"nancyhun@ukr.net",
	"kogut.ira@gmail.com",
	"rockstaroffrontend@gmail.com",
	"mburdoev@gmail.com",
	"alexserdiuchenko@gmail.com",
	"osuflak22@gmail.com",
	"alex.kamniev+admin1@dripify.io",
	"kushnirpetro92@gmail.com",
	"yanabril01@gmail.com",
	"alex.t@dripify.io",
	"ernest.boyega@gmail.com",
	"kristinayuriivna2020@gmail.com",
	"maryna.fedyk@gmail.com",
	"evkram87@gmail.com",
	"anjmirafuentes@gmail.com",
	"tanduyan.johncarlo@dnsc.edu.ph",
	"plukreybolo@gmail.com",
	"i.yurchyna@gmail.com",
	"nadyadem.88@gmail.com",
	"amecasidsid@gmail.com",
	"jhane.escamos11@gmail.com",
	"alex.k@dripify.io",
	"annapershutkina@gmail.com",
	"dlalinkedin5@gmail.com",
	"anatolie.clevan@gmail.com",
	"helen.d@dripify.io",
	"max.p@dripify.io",
	"amecasidsid@gmail.com",
	"jhane.escamos11@gmail.com",
	"kushnirpetro92@gmail.com",
	"annapershutkina@gmail.com",
	"marie.f@dripify.io",
	"kate.k@dripify.io",
	"joanna.b@dripify.io",
	"sasha@dripify.io",
	"john@dripify.io",
	"rey@dripify.io",
	"elliott.y@dripify.io",
	"nadine@dripify.io",
	"erin.c@dripify.io",
	"hannah@dripify.io"
]
